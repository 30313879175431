<template>
  <div class="publish">
    <div style="background-color: #FAFAFA;">
      <button @click="toIndex" class="publish-top">
        <span>发布信息</span>
      </button>
      <div class="publish-content">
        <el-form :inline="true" :model="publishForm" label-width="100px" label-position="right" style="width: 800px;margin-top: 20px;">
          <el-form-item label="商品封面">
            <el-upload class="avatar-uploader" action="http://192.168.168.80:18081/api/upload" :show-file-list="false"
              :on-success="handleAvatarSuccess">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="商品名称">
            <el-input v-model="publishForm.name" placeholder="30字以内" class="input-msg"></el-input>
          </el-form-item>
          <!-- <el-form-item label="联系方式">
            <el-input v-model="publishForm.phone" class="input-msg"></el-input>
          </el-form-item> -->
          <el-form-item label="商品分类" class="table-msg-inline">
            <el-select v-model="publishForm.categoryId" placeholder="艺术与设计">
              <el-option v-for="category in categoryList" :key="category.id" :label="category.name"
                :value="category.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="价格" class="table-msg-inline">
            <el-input v-model="publishForm.topPrice"></el-input>
          </el-form-item>
          <!-- <el-form-item label="删除线价格">
            <el-input v-model="publishForm.bottomPrice" class="input-msg"></el-input>
          </el-form-item>
          <el-form-item v-if="true" label="库存">
            <el-input v-model="publishForm.repertory" class="input-msg"></el-input>
          </el-form-item>
          <el-form-item v-if="true" label="快递运费">
            <el-input v-model="publishForm.freight" class="input-msg"></el-input>
          </el-form-item> -->
          <el-form-item v-if="true" label="货源地">
            <el-input v-model="publishForm.goodsSource" class="input-msg"></el-input>
          </el-form-item>
          <el-form-item v-if="true" label="语种">
            <el-input v-model="publishForm.language" class="input-msg"></el-input>
          </el-form-item>
          <el-form-item v-if="true" label="出版社">
            <el-input v-model="publishForm.publisherName" class="input-msg"></el-input>
          </el-form-item>
          <el-form-item v-if="true" label="出版年">
            <el-input v-model="publishForm.publishTime" class="input-msg"></el-input>
          </el-form-item>
          <el-form-item v-if="true" label="发布人">
            <el-input v-model="publishForm.createdName" class="input-msg"></el-input>
          </el-form-item>
          <el-form-item v-if="true" label="联系方式">
            <el-input v-model="publishForm.phone" class="input-msg"></el-input>
          </el-form-item>
          <el-form-item v-if="true" label="内容简介">
            <textarea v-model="publishForm.summary" placeholder="详情文本编辑" style="width: 650px;height: 150px;resize: none;"></textarea>
          </el-form-item>
          <el-form-item class="button-list">
            <button class="form-button" @click="onSubmit"><img src="@/assets/save.png"></button>
            <button class="form-button" @click="onSubmit"><img src="@/assets/publish.png"></button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        imageUrl: "",
        publishForm: {
          cover: "",
          name: "",
          publishTime: "",
          publishName: "",
          goodsSource: "",
          language: "",
          createdName: "",
          phone: "",
          categoryId: "",
          topPrice: "",
          /* bottomPrice: "",
          repertory: "",
          freight: null, */
          summary: "",
        },
        categoryList: [],
      }
    },
    created() {
      this.initCategoryList();
    },
    methods: {
      async initCategoryList() {
        const data = await this.$Axios.getCategory();
        this.categoryList = data.data
        for (let i = 0; i < data.data.length; i++) {
          if (data.data[i].name == '艺术与设计') {
            this.publishForm.id = data.data[i].id
          }
        }
      },
      async onSubmit() {
		this.$alert('请先登录', '提醒');
        // if (this.publishForm.cover == '') {
        //   this.$alert('请添加图片', '提醒')
        // } else {
        //   const data = await this.$Axios.addBookSale(this.publishForm);
        //   this.$message(data.msg);
        //   this.initCategoryList();
        // }
      },
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
        this.publishForm.cover = res.data
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },
      toIndex() {
        this.$router.push({
          name: 'Home'
        });
      }
    }
  }
</script>

<style>
  .publish-top {
    border: 0px;
    text-align: left;
    width: 80px;
    height: 28px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 24px;
    color: #413434;
    opacity: 1;
    background-color: #FAFAFA;
  }
  
  .publish-content {
    margin-right: 57px;
    margin-left: 27px;
    padding-right: 60px;
    background-color: #FFFFFF;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
    background-color: #E8E9EC;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .button-list {
   margin-left: 100px;
  }

  .form-button {
    margin-left: 20px;
    margin-bottom: 50px;
    border: 0px;
    background-color: #FFFFFF;
  }

  .input-msg {
    width: 650px;
  }

  .table-msg-inline {
    width: 300;
  }
</style>
